import RelatedContentStatusModel from '@models/shop/RelatedContentStatusModel';
import doc                       from '@widesk/decorators/doc';
import model                     from '@widesk/decorators/model';
import ShopServiceModel          from '@/models/ShopServiceModel';
import TimestampAble             from '@widesk/models/traits/TimestampAble';
import Blamable                  from '@/models/traits/Blamable';
import Translatable              from '@widesk/models/traits/Translatable';
import { RenderNameProps }       from '@widesk/mixins/ApiModelRenderName';

@model.urnResource('related_content')
@doc.path('/related_contents/{?id}')
export default class RelatedContentModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'label': true,
	'content': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'relatedContentStatus': id;
		'relatedContentStatus.reference': Shop_RelatedContentStatusReference;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.string declare link: string;
	@doc.number declare priceInclTax: number;
	@doc.model(RelatedContentStatusModel) declare relatedContentStatus: RelatedContentStatusModel;

	public renderName(props?: RenderNameProps<this> & RelatedContentProps) {
		return super.renderName(props);
	}
}
