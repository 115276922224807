import EntertainmentModel  from '@models/shop/EntertainmentModel';
import ProductGroupModel   from '@models/shop/ProductGroupModel';
import ProductStatusModel  from '@models/shop/ProductStatusModel';
import doc                 from '@widesk/decorators/doc';
import model               from '@widesk/decorators/model';
import ShopServiceModel    from '@/models/ShopServiceModel';
import TimestampAble       from '@widesk/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';
import Translatable        from '@widesk/models/traits/Translatable';
import AccessTypeModel     from '@models/scaf/AccessTypeModel';
import PagedCollection     from '@widesk/models/PagedCollection';
import { RenderNameProps } from '@widesk/mixins/ApiModelRenderName';

@model.urnResource('product')
@doc.path('/products/{?id}')
export default class ProductModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'title': true,
	'description': true,
	'badgeInformation': true,
}))) {
	public accessType = new AccessTypeModel();

	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'endDate[after]': string | Date | Dayjs;
		'endDate[before]': string | Date | Dayjs;
		'endDate[strictly_after]': string | Date | Dayjs;
		'endDate[strictly_before]': string | Date | Dayjs;
		'entertainment': id;
		'entertainment.entertainmentUrn': Urn;
		'productGroup': id;
		'productGroup.productGroupStatus': id;
		'productGroup.productGroupStatus.reference': Shop_ProductGroupStatusReference;
		'productGroup.productGroupTaxonomies.taxonomy': id;
		'productStatus': id;
		'productStatus.reference': Shop_ProductStatusReference;
		'slug': id;
		'startDate[after]': string | Date | Dayjs;
		'startDate[before]': string | Date | Dayjs;
		'startDate[strictly_after]': string | Date | Dayjs;
		'startDate[strictly_before]': string | Date | Dayjs;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'endDate': string;
		'id': string;
		'position': string;
		'productGroup.label': string;
		'startDate': string;
		'translations.title': string;
		'updatedAt': string;
		'entertainment.label': string;
	};

	@doc.date declare endDate: Dayjs;
	@doc.model(EntertainmentModel) declare entertainment: EntertainmentModel;
	@doc.number declare maxQuantity: number;
	@doc.number declare position: number;
	@doc.model(ProductGroupModel) declare productGroup: ProductGroupModel;
	@doc.model(ProductStatusModel) declare productStatus: ProductStatusModel;
	@doc.string declare slug: string;
	@doc.date declare startDate: Dayjs;

	public renderName(props?: RenderNameProps<this> & ProductNameProps) {
		return super.renderName(props);
	}

	public async fetchAccessType() {
		const collection = new PagedCollection(AccessTypeModel);
		await collection
			.clear()
			.setItemsPerPage(1)
			.setFilter('sourceUrn', this.urn)
			.list();

		const model = collection.first();

		if (model) this.accessType.set(collection.first()?.attributes);

		this.accessType.setIsLoaded(true);
	}
}