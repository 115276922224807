import ApiConnector from '@/models/ApiConnector';
import ServiceModel from '@models/ServiceModel';

const service = window.APP_CONFIG.services.find(service => service.id === 'shop');

export const shopConnector = new ApiConnector({ baseURL: service?.configurations.api_endpoint });

export default class ShopServiceModel extends ServiceModel {
	public static get connector() {
		return shopConnector;
	}

	public static serviceName = 'shop';
	public static partitionName = 'jts';
}
