import MailTypeModel    from '@models/mail/MailTypeModel';
import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';
import Translatable     from '@widesk/models/traits/Translatable';

@model.urnResource('mail')
@doc.path('/stonecode_mail_mails/{?id}')
export default class MailModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'subject': true,
	'description': true,
	'content': true,
}))) {
	private declare _tsFilters: unknown;

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
		'label': string;
	};

	@doc.string declare bcc: string;
	@doc.string declare cc: string;
	@doc.string declare mailFrom: string;
	@doc.string declare label: string;
	@doc.model(MailTypeModel) declare mailType: MailTypeModel;
	@doc.string declare reference: string;
	@doc.string declare template: string;
}
