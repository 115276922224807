import { createBrowserRouter }                   from 'react-router-dom';
import { RouterProvider as ReactRouterProvider } from 'react-router-dom';
import AuthLayout                                from '@widesk/layouts/AuthLayout';
import { useAuth }                               from '@widesk/hooks/useAuth';
import LoginPage                                 from '@widesk/pages/LoginPage';
import Layout                                    from '@widesk/layouts/Layout';
import LayoutSider                               from '@/components/LayoutSider';
import LayoutHeader                              from '@/components/LayoutHeader';
import BreadcrumbBar                             from '@widesk/components/BreadcrumbBar';
import ErrorPage                                 from '@widesk/pages/ErrorPage';
import View                                      from '@widesk/components/View';
import Spin                                      from '@widesk/components/Spin';
import Button                                    from '@widesk/components/Button';
import Title                                     from '@widesk/components/Title';
import useTheme                                  from '@widesk/hooks/useTheme';

// On charge tous les fichiers "Route" du projet
const modules = import.meta.glob([
	'./routes/*Route.ts',
	'./routes/*Route.tsx',
	'./routes/services/*/*Route.ts',
	'./routes/services/*/*Route.tsx',
], { eager: true });

const publicRouter = createBrowserRouter([
	{
		element: <Layout />,
		children: [
			{
				path: '*',
				element: (
					<LoginPage
						footer={<Button type="link" onClick={() => alert('TODO')}>Mot de passe oublié</Button>}
						header={<Title center align="center">Billetterie</Title>}
					/>
				),
			},
		],
	},
]);

const privateRouter = createBrowserRouter([
	{
		element: (
			<AuthLayout
				renderHeader={() => <LayoutHeader />}
				renderSider={() => <LayoutSider />}
				renderTopPage={() => <BreadcrumbBar />}
				initialCollapsed={false}
			/>
		),
		children: [
			...Object.values(modules).flatMap((module: any) => module.default),
		],
		errorElement: <ErrorPage />,
	},
]);

export default function RouterProvider() {
	const { token, isLogging } = useAuth();

	return (
		<>
			{token ? (
				isLogging ? <LoadingPage /> : <ReactRouterProvider router={privateRouter} />
			) : (
				<ReactRouterProvider router={publicRouter} />
			)}
		</>
	);
}

const LoadingPage = () => {
	const { logout } = useAuth();
	const theme = useTheme();

	return (
		<View height="100vh" center gap={20} bg={theme.colorBgContainer} color={theme.colorTextBase}>
			<Spin size="large" tip="Chargement de l'application" />

			<Button type="link" onClick={() => logout()}>Déconnexion</Button>
		</View>
	);
};
