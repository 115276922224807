import ApiConnector from '@/models/ApiConnector';
import ServiceModel from '@models/ServiceModel';

const service = window.APP_CONFIG.services.find(service => service.id === 'code');

export const codeConnector = new ApiConnector({ baseURL: service?.configurations.api_endpoint });

export default class CodeServiceModel extends ServiceModel {
	public static get connector() {
		return codeConnector;
	}

	public static serviceName = 'code';
	public static partitionName = 'jts';
}
