import PeriodGroupModel from '@models/shop/PeriodGroupModel';
import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';
import Translatable     from '@widesk/models/traits/Translatable';

@model.urnResource('period')
@doc.path('/periods/{?id}')
export default class PeriodModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'title': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'periodGroup': id;
		'periodGroup.entertainment': id;
		'periodGroup.entertainment.entertainmentUrn': Urn;
		'startDate[after]': string | Date | Dayjs;
		'startDate[before]': string | Date | Dayjs;
		'startDate[strictly_after]': string | Date | Dayjs;
		'startDate[strictly_before]': string | Date | Dayjs;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'startDate': string;
		'translations.title': string;
		'updatedAt': string;
	};	

	@doc.model(PeriodGroupModel) declare periodGroup: PeriodGroupModel;
	@doc.date declare startDate: Dayjs;
}