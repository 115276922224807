import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import CodeServiceModel from '@/models/CodeServiceModel';

@model.cacheDuration()
@model.urnResource('code_status')
@doc.path('/code_statuses/{?id}')
export default class CodeStatusModel extends CodeServiceModel {
	private declare _tsFilters: {
		'reference': Code_CodeStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Code_CodeStatusReference;

	public get color() {
		switch (this.reference) {
			case 'enabled':
				return 'green';
			case 'disabled':
			case 'canceled':
				return 'red';
		}
	}
}
