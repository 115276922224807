export default [
	{
		handle: { crumb: () => `Interface d'expédition` },
		path: '/deliverables/shipping/:id?',
		lazy: async () => {
			const Module = await import('@pages/shop/DeliverableShipping/DeliverableShippingPage');
			return { Component: Module.default };
		},
	},
];
