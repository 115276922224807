import ScafServiceModel from '@/models/ScafServiceModel';
import { computed }     from 'mobx';

export default class AccessTypeModel extends ScafServiceModel {
	private declare _tsFilters: {
		'sourceUrn': Urn;
	};

	static get path(): string {
		return '/access_types';
	}

	public get title(): string {
		return this.get('title', '');
	}

	public get sourceUrn(): Urn {
		return this.get('source_urn', '');
	}

	@computed get dashboardLink(): string {
		const scafService = window.APP_CONFIG.services.find(service => service.id === 'scaf');
		return `${scafService?.url}#/access/types/${this.id}/edit/infos`;
	}
}
