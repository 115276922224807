import doc                 from '@widesk/decorators/doc';
import model               from '@widesk/decorators/model';
import ShopServiceModel    from '@/models/ShopServiceModel';
import TimestampAble       from '@widesk/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';
import FondationsUserModel from '@models/fondations/UserModel';
import { RenderNameProps } from '@widesk/mixins/ApiModelRenderName';

@model.urnResource('client')
@doc.path('/clients/{?id}')
export default class ClientModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'clientClientGroups.clientGroup': id;
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
		'userUrn': Urn;
	};
	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.modelUrn(FondationsUserModel) declare user: FondationsUserModel;

	public renderName(props?: RenderNameProps<this> & ClientNameProps) {
		return super.renderName(props);
	}
}
