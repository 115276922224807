import OrderModel                     from '@models/shop/OrderModel';
import ProductPricingClientGroupModel from '@models/shop/ProductPricingClientGroupModel';
import VatModel                       from '@models/shop/VatModel';
import doc                            from '@widesk/decorators/doc';
import model                          from '@widesk/decorators/model';
import ShopServiceModel               from '@/models/ShopServiceModel';
import TimestampAble                  from '@widesk/models/traits/TimestampAble';
import Blamable                       from '@/models/traits/Blamable';
import ProductModel                   from '@models/shop/ProductModel';

@model.urnResource('order_item')
@doc.path('/order_items/{?id}')
export default class OrderItemModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'order': id;
		'product': id;
		'productPricingClientGroup': id;
		'productPricingClientGroup.productPricing': id;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'id': string;
		'createdAt': string;
		'updatedAt': string;
	};	

	@doc.string declare label: string;
	@doc.model(OrderModel) declare order: OrderModel;
	@doc.model(ProductModel) declare product: ProductModel;
	@doc.model(ProductPricingClientGroupModel) declare productPricingClientGroup: ProductPricingClientGroupModel;
	@doc.number declare quantity: number;
	@doc.string declare rate: string;
	@doc.number declare unitPriceExclVat: number;
	@doc.number declare unitPriceInclVat: number;
	@doc.number declare unitPriceVat: number;
	@doc.model(VatModel) declare vat: VatModel;

	public get amountExclTax() {
		return this.unitPriceExclVat * this.quantity;
	}

	public get amountInclTax() {
		return this.unitPriceInclVat * this.quantity;
	}
}

// TODO DOC - Ajouter la propriété "source"