import EntertainmentModel from '@models/shop/EntertainmentModel';
import doc                from '@widesk/decorators/doc';
import model              from '@widesk/decorators/model';
import ShopServiceModel   from '@/models/ShopServiceModel';
import TimestampAble      from '@widesk/models/traits/TimestampAble';
import Blamable           from '@/models/traits/Blamable';

@model.urnResource('period_group')
@doc.path('/period_groups/{?id}')
export default class PeriodGroupModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'entertainment': id;
		'entertainment.entertainmentUrn': Urn;
		'label': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'updatedAt': string;
	};

	@doc.model(EntertainmentModel) declare entertainment: EntertainmentModel;
	@doc.string declare label: string;
}
