import PromoCodeStatusModel from '@models/shop/PromoCodeStatusModel';
import PromoCodeTypeModel   from '@models/shop/PromoCodeTypeModel';
import doc                  from '@widesk/decorators/doc';
import model                from '@widesk/decorators/model';
import ShopServiceModel     from '@/models/ShopServiceModel';
import TimestampAble        from '@widesk/models/traits/TimestampAble';
import Blamable             from '@/models/traits/Blamable';

@model.urnResource('promo_code')
@doc.path('/promo_codes/{?id}')
export default class PromoCodeModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'endDate[after]': string | Date | Dayjs;
		'endDate[before]': string | Date | Dayjs;
		'endDate[strictly_after]': string | Date | Dayjs;
		'endDate[strictly_before]': string | Date | Dayjs;
		'promoCodeStatus': id;
		'promoCodeStatus.reference': Shop_PromoCodeStatusReference;
		'promoCodeType': id;
		'promoCodeType.reference': Shop_PromoCodeTypeReference;
		'startDate[after]': string | Date | Dayjs;
		'startDate[before]': string | Date | Dayjs;
		'startDate[strictly_after]': string | Date | Dayjs;
		'startDate[strictly_before]': string | Date | Dayjs;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.string declare code: string;
	@doc.date declare endDate?: Dayjs;
	@doc.string declare label: string;
	@doc.number declare minAmount: number;
	@doc.model(PromoCodeStatusModel) declare promoCodeStatus: PromoCodeStatusModel;
	@doc.model(PromoCodeTypeModel) declare promoCodeType: PromoCodeTypeModel;
	@doc.date declare startDate: Dayjs;
}
