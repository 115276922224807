import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.urnResource('client_group')
@doc.path('/client_groups/{?id}')
export default class ClientGroupModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'clientClientGroups.client': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'label': string;
		'reference': Shop_ClientGroupReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
		'updatedAt': string;
	};

	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: Shop_ClientGroupReference;
}
