/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Platform = $root.Platform = (() => {

    /**
     * Namespace Platform.
     * @exports Platform
     * @namespace
     */
    const Platform = {};

    Platform.Common = (function() {

        /**
         * Namespace Common.
         * @memberof Platform
         * @namespace
         */
        const Common = {};

        Common.Entity = (function() {

            /**
             * Namespace Entity.
             * @memberof Platform.Common
             * @namespace
             */
            const Entity = {};

            Entity.Urn = (function() {

                /**
                 * Properties of an Urn.
                 * @memberof Platform.Common.Entity
                 * @interface IUrn
                 * @property {string|null} [partition] Urn partition
                 * @property {string|null} [service] Urn service
                 * @property {string|null} [resource] Urn resource
                 * @property {string|null} [identifier] Urn identifier
                 */

                /**
                 * Constructs a new Urn.
                 * @memberof Platform.Common.Entity
                 * @classdesc Represents an Urn.
                 * @implements IUrn
                 * @constructor
                 * @param {Platform.Common.Entity.IUrn=} [properties] Properties to set
                 */
                function Urn(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Urn partition.
                 * @member {string} partition
                 * @memberof Platform.Common.Entity.Urn
                 * @instance
                 */
                Urn.prototype.partition = "";

                /**
                 * Urn service.
                 * @member {string} service
                 * @memberof Platform.Common.Entity.Urn
                 * @instance
                 */
                Urn.prototype.service = "";

                /**
                 * Urn resource.
                 * @member {string} resource
                 * @memberof Platform.Common.Entity.Urn
                 * @instance
                 */
                Urn.prototype.resource = "";

                /**
                 * Urn identifier.
                 * @member {string} identifier
                 * @memberof Platform.Common.Entity.Urn
                 * @instance
                 */
                Urn.prototype.identifier = "";

                /**
                 * Creates a new Urn instance using the specified properties.
                 * @function create
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {Platform.Common.Entity.IUrn=} [properties] Properties to set
                 * @returns {Platform.Common.Entity.Urn} Urn instance
                 */
                Urn.create = function create(properties) {
                    return new Urn(properties);
                };

                /**
                 * Encodes the specified Urn message. Does not implicitly {@link Platform.Common.Entity.Urn.verify|verify} messages.
                 * @function encode
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {Platform.Common.Entity.IUrn} message Urn message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Urn.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.partition != null && Object.hasOwnProperty.call(message, "partition"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.partition);
                    if (message.service != null && Object.hasOwnProperty.call(message, "service"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.service);
                    if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.resource);
                    if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.identifier);
                    return writer;
                };

                /**
                 * Encodes the specified Urn message, length delimited. Does not implicitly {@link Platform.Common.Entity.Urn.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {Platform.Common.Entity.IUrn} message Urn message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Urn.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Urn message from the specified reader or buffer.
                 * @function decode
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Platform.Common.Entity.Urn} Urn
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Urn.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Platform.Common.Entity.Urn();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.partition = reader.string();
                            break;
                        case 2:
                            message.service = reader.string();
                            break;
                        case 3:
                            message.resource = reader.string();
                            break;
                        case 4:
                            message.identifier = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Urn message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Platform.Common.Entity.Urn} Urn
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Urn.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Urn message.
                 * @function verify
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Urn.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.partition != null && message.hasOwnProperty("partition"))
                        if (!$util.isString(message.partition))
                            return "partition: string expected";
                    if (message.service != null && message.hasOwnProperty("service"))
                        if (!$util.isString(message.service))
                            return "service: string expected";
                    if (message.resource != null && message.hasOwnProperty("resource"))
                        if (!$util.isString(message.resource))
                            return "resource: string expected";
                    if (message.identifier != null && message.hasOwnProperty("identifier"))
                        if (!$util.isString(message.identifier))
                            return "identifier: string expected";
                    return null;
                };

                /**
                 * Creates an Urn message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Platform.Common.Entity.Urn} Urn
                 */
                Urn.fromObject = function fromObject(object) {
                    if (object instanceof $root.Platform.Common.Entity.Urn)
                        return object;
                    let message = new $root.Platform.Common.Entity.Urn();
                    if (object.partition != null)
                        message.partition = String(object.partition);
                    if (object.service != null)
                        message.service = String(object.service);
                    if (object.resource != null)
                        message.resource = String(object.resource);
                    if (object.identifier != null)
                        message.identifier = String(object.identifier);
                    return message;
                };

                /**
                 * Creates a plain object from an Urn message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Platform.Common.Entity.Urn
                 * @static
                 * @param {Platform.Common.Entity.Urn} message Urn
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Urn.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.partition = "";
                        object.service = "";
                        object.resource = "";
                        object.identifier = "";
                    }
                    if (message.partition != null && message.hasOwnProperty("partition"))
                        object.partition = message.partition;
                    if (message.service != null && message.hasOwnProperty("service"))
                        object.service = message.service;
                    if (message.resource != null && message.hasOwnProperty("resource"))
                        object.resource = message.resource;
                    if (message.identifier != null && message.hasOwnProperty("identifier"))
                        object.identifier = message.identifier;
                    return object;
                };

                /**
                 * Converts this Urn to JSON.
                 * @function toJSON
                 * @memberof Platform.Common.Entity.Urn
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Urn.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Urn;
            })();

            return Entity;
        })();

        return Common;
    })();

    Platform.Service = (function() {

        /**
         * Namespace Service.
         * @memberof Platform
         * @namespace
         */
        const Service = {};

        Service.Fondations = (function() {

            /**
             * Namespace Fondations.
             * @memberof Platform.Service
             * @namespace
             */
            const Fondations = {};

            Fondations.UserRights = (function() {

                /**
                 * Properties of a UserRights.
                 * @memberof Platform.Service.Fondations
                 * @interface IUserRights
                 * @property {Platform.Common.Entity.IUrn|null} [urn] UserRights urn
                 * @property {Array.<Platform.Common.Entity.IUrn>|null} [groups] UserRights groups
                 * @property {Array.<Platform.Service.Fondations.UserRights.IRole>|null} [roles] UserRights roles
                 */

                /**
                 * Constructs a new UserRights.
                 * @memberof Platform.Service.Fondations
                 * @classdesc Represents a UserRights.
                 * @implements IUserRights
                 * @constructor
                 * @param {Platform.Service.Fondations.IUserRights=} [properties] Properties to set
                 */
                function UserRights(properties) {
                    this.groups = [];
                    this.roles = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserRights urn.
                 * @member {Platform.Common.Entity.IUrn|null|undefined} urn
                 * @memberof Platform.Service.Fondations.UserRights
                 * @instance
                 */
                UserRights.prototype.urn = null;

                /**
                 * UserRights groups.
                 * @member {Array.<Platform.Common.Entity.IUrn>} groups
                 * @memberof Platform.Service.Fondations.UserRights
                 * @instance
                 */
                UserRights.prototype.groups = $util.emptyArray;

                /**
                 * UserRights roles.
                 * @member {Array.<Platform.Service.Fondations.UserRights.IRole>} roles
                 * @memberof Platform.Service.Fondations.UserRights
                 * @instance
                 */
                UserRights.prototype.roles = $util.emptyArray;

                /**
                 * Creates a new UserRights instance using the specified properties.
                 * @function create
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {Platform.Service.Fondations.IUserRights=} [properties] Properties to set
                 * @returns {Platform.Service.Fondations.UserRights} UserRights instance
                 */
                UserRights.create = function create(properties) {
                    return new UserRights(properties);
                };

                /**
                 * Encodes the specified UserRights message. Does not implicitly {@link Platform.Service.Fondations.UserRights.verify|verify} messages.
                 * @function encode
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {Platform.Service.Fondations.IUserRights} message UserRights message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserRights.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                        $root.Platform.Common.Entity.Urn.encode(message.urn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.groups != null && message.groups.length)
                        for (let i = 0; i < message.groups.length; ++i)
                            $root.Platform.Common.Entity.Urn.encode(message.groups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.roles != null && message.roles.length)
                        for (let i = 0; i < message.roles.length; ++i)
                            $root.Platform.Service.Fondations.UserRights.Role.encode(message.roles[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UserRights message, length delimited. Does not implicitly {@link Platform.Service.Fondations.UserRights.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {Platform.Service.Fondations.IUserRights} message UserRights message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserRights.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserRights message from the specified reader or buffer.
                 * @function decode
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Platform.Service.Fondations.UserRights} UserRights
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserRights.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Platform.Service.Fondations.UserRights();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.urn = $root.Platform.Common.Entity.Urn.decode(reader, reader.uint32());
                            break;
                        case 2:
                            if (!(message.groups && message.groups.length))
                                message.groups = [];
                            message.groups.push($root.Platform.Common.Entity.Urn.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            if (!(message.roles && message.roles.length))
                                message.roles = [];
                            message.roles.push($root.Platform.Service.Fondations.UserRights.Role.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserRights message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Platform.Service.Fondations.UserRights} UserRights
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserRights.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserRights message.
                 * @function verify
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserRights.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.urn != null && message.hasOwnProperty("urn")) {
                        let error = $root.Platform.Common.Entity.Urn.verify(message.urn);
                        if (error)
                            return "urn." + error;
                    }
                    if (message.groups != null && message.hasOwnProperty("groups")) {
                        if (!Array.isArray(message.groups))
                            return "groups: array expected";
                        for (let i = 0; i < message.groups.length; ++i) {
                            let error = $root.Platform.Common.Entity.Urn.verify(message.groups[i]);
                            if (error)
                                return "groups." + error;
                        }
                    }
                    if (message.roles != null && message.hasOwnProperty("roles")) {
                        if (!Array.isArray(message.roles))
                            return "roles: array expected";
                        for (let i = 0; i < message.roles.length; ++i) {
                            let error = $root.Platform.Service.Fondations.UserRights.Role.verify(message.roles[i]);
                            if (error)
                                return "roles." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a UserRights message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Platform.Service.Fondations.UserRights} UserRights
                 */
                UserRights.fromObject = function fromObject(object) {
                    if (object instanceof $root.Platform.Service.Fondations.UserRights)
                        return object;
                    let message = new $root.Platform.Service.Fondations.UserRights();
                    if (object.urn != null) {
                        if (typeof object.urn !== "object")
                            throw TypeError(".Platform.Service.Fondations.UserRights.urn: object expected");
                        message.urn = $root.Platform.Common.Entity.Urn.fromObject(object.urn);
                    }
                    if (object.groups) {
                        if (!Array.isArray(object.groups))
                            throw TypeError(".Platform.Service.Fondations.UserRights.groups: array expected");
                        message.groups = [];
                        for (let i = 0; i < object.groups.length; ++i) {
                            if (typeof object.groups[i] !== "object")
                                throw TypeError(".Platform.Service.Fondations.UserRights.groups: object expected");
                            message.groups[i] = $root.Platform.Common.Entity.Urn.fromObject(object.groups[i]);
                        }
                    }
                    if (object.roles) {
                        if (!Array.isArray(object.roles))
                            throw TypeError(".Platform.Service.Fondations.UserRights.roles: array expected");
                        message.roles = [];
                        for (let i = 0; i < object.roles.length; ++i) {
                            if (typeof object.roles[i] !== "object")
                                throw TypeError(".Platform.Service.Fondations.UserRights.roles: object expected");
                            message.roles[i] = $root.Platform.Service.Fondations.UserRights.Role.fromObject(object.roles[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a UserRights message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Platform.Service.Fondations.UserRights
                 * @static
                 * @param {Platform.Service.Fondations.UserRights} message UserRights
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserRights.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.groups = [];
                        object.roles = [];
                    }
                    if (options.defaults)
                        object.urn = null;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = $root.Platform.Common.Entity.Urn.toObject(message.urn, options);
                    if (message.groups && message.groups.length) {
                        object.groups = [];
                        for (let j = 0; j < message.groups.length; ++j)
                            object.groups[j] = $root.Platform.Common.Entity.Urn.toObject(message.groups[j], options);
                    }
                    if (message.roles && message.roles.length) {
                        object.roles = [];
                        for (let j = 0; j < message.roles.length; ++j)
                            object.roles[j] = $root.Platform.Service.Fondations.UserRights.Role.toObject(message.roles[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this UserRights to JSON.
                 * @function toJSON
                 * @memberof Platform.Service.Fondations.UserRights
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserRights.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                UserRights.Role = (function() {

                    /**
                     * Properties of a Role.
                     * @memberof Platform.Service.Fondations.UserRights
                     * @interface IRole
                     * @property {Platform.Common.Entity.IUrn|null} [urn] Role urn
                     */

                    /**
                     * Constructs a new Role.
                     * @memberof Platform.Service.Fondations.UserRights
                     * @classdesc Represents a Role.
                     * @implements IRole
                     * @constructor
                     * @param {Platform.Service.Fondations.UserRights.IRole=} [properties] Properties to set
                     */
                    function Role(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Role urn.
                     * @member {Platform.Common.Entity.IUrn|null|undefined} urn
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @instance
                     */
                    Role.prototype.urn = null;

                    /**
                     * Creates a new Role instance using the specified properties.
                     * @function create
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {Platform.Service.Fondations.UserRights.IRole=} [properties] Properties to set
                     * @returns {Platform.Service.Fondations.UserRights.Role} Role instance
                     */
                    Role.create = function create(properties) {
                        return new Role(properties);
                    };

                    /**
                     * Encodes the specified Role message. Does not implicitly {@link Platform.Service.Fondations.UserRights.Role.verify|verify} messages.
                     * @function encode
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {Platform.Service.Fondations.UserRights.IRole} message Role message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Role.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                            $root.Platform.Common.Entity.Urn.encode(message.urn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Role message, length delimited. Does not implicitly {@link Platform.Service.Fondations.UserRights.Role.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {Platform.Service.Fondations.UserRights.IRole} message Role message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Role.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Role message from the specified reader or buffer.
                     * @function decode
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Platform.Service.Fondations.UserRights.Role} Role
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Role.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Platform.Service.Fondations.UserRights.Role();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.urn = $root.Platform.Common.Entity.Urn.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Role message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Platform.Service.Fondations.UserRights.Role} Role
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Role.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Role message.
                     * @function verify
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Role.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.urn != null && message.hasOwnProperty("urn")) {
                            let error = $root.Platform.Common.Entity.Urn.verify(message.urn);
                            if (error)
                                return "urn." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Role message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Platform.Service.Fondations.UserRights.Role} Role
                     */
                    Role.fromObject = function fromObject(object) {
                        if (object instanceof $root.Platform.Service.Fondations.UserRights.Role)
                            return object;
                        let message = new $root.Platform.Service.Fondations.UserRights.Role();
                        if (object.urn != null) {
                            if (typeof object.urn !== "object")
                                throw TypeError(".Platform.Service.Fondations.UserRights.Role.urn: object expected");
                            message.urn = $root.Platform.Common.Entity.Urn.fromObject(object.urn);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Role message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @static
                     * @param {Platform.Service.Fondations.UserRights.Role} message Role
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Role.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.urn = null;
                        if (message.urn != null && message.hasOwnProperty("urn"))
                            object.urn = $root.Platform.Common.Entity.Urn.toObject(message.urn, options);
                        return object;
                    };

                    /**
                     * Converts this Role to JSON.
                     * @function toJSON
                     * @memberof Platform.Service.Fondations.UserRights.Role
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Role.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Role;
                })();

                return UserRights;
            })();

            return Fondations;
        })();

        return Service;
    })();

    return Platform;
})();

export { $root as default };
