import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.cacheDuration()
@model.urnResource('mail_type')
@doc.path('/stonecode_mail_mail_types/{?id}')
export default class MailTypeModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: unknown;

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: string;
}
