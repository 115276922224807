import ExpirationTypeModel     from '@models/shop/ExpirationTypeModel';
import ProductGroupStatusModel from '@models/shop/ProductGroupStatusModel';
import ProductTypeModel        from '@models/shop/ProductTypeModel';
import VatModel                from '@models/shop/VatModel';
import doc                     from '@widesk/decorators/doc';
import model                   from '@widesk/decorators/model';
import ShopServiceModel        from '@/models/ShopServiceModel';
import TimestampAble           from '@widesk/models/traits/TimestampAble';
import Blamable                from '@/models/traits/Blamable';
import Translatable            from '@widesk/models/traits/Translatable';

@model.urnResource('product_group')
@doc.path('/product_groups/{?id}')
export default class ProductGroupModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'information': true,
	'termsOfUse': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'label': string;
		'productType': id;
		'productGroupStatus': id;
		'productGroupStatus.reference': Shop_ProductGroupStatusReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
		'vat': id;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'createdAt': string;
		'updatedAt': string;
	};

	@doc.number declare bankDelay: number;
	@doc.model(ExpirationTypeModel) declare expirationType: ExpirationTypeModel;
	@doc.string declare label: string;
	@doc.boolean declare manualValidation: boolean;
	@doc.number declare maxQuantityPerOrder: number;
	@doc.model(ProductGroupStatusModel) declare productGroupStatus: ProductGroupStatusModel;
	@doc.model(ProductTypeModel) declare productType: ProductTypeModel;
	@doc.model(VatModel) declare vat: VatModel;
}
