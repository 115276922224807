import WideskNavigation       from '@widesk/components/Navigation';
import SiderSelectProject     from '@/components/SiderSelectProject';
import navigation             from '@/navigation';
import { navigationSettings } from '@/navigation';
import useTheme               from '@widesk/hooks/useTheme';
import View                   from '@widesk/components/View';

export default function LayoutSider() {
	const theme = useTheme();

	return (
		<>
			<View flex style={{ overflow: 'auto' }}>
				<SiderSelectProject />

				<View flex style={{ overflow: 'auto' }}>
					<WideskNavigation items={navigation} />
				</View>
			</View>

			<div>
				{/* Paramètres */}
				<WideskNavigation
					style={{ background: theme.colorPrimary }}
					items={navigationSettings}
				/>
			</div>
		</>
	);
}
