export default [
	{
		handle: { crumb: () => 'Distribution des badges' },
		path: '/deliverables/badge_delivery',
		lazy: async () => {
			const Module = await import('@pages/shop/DeliverableBadgeDelivery/DeliverableBadgeDeliveryPage');
			return { Component: Module.default };
		},
	},
];
