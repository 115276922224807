import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.urnResource('network')
@doc.path('/networks/{?id}')
export default class NetworkModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'label': string;
		'reference': Shop_NetworkReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'reference': string;
	};

	@doc.string declare label: string;
	@doc.number declare orderNumber: number;
	@doc.string declare reference: Shop_NetworkReference;

	public static getIdFromReference(reference: Shop_NetworkReference) {
		const ids: Partial<Record<Shop_NetworkReference, string>> = {
			shop: '1',
			fnac: '2',
			ticketnet: '3',
		};

		return ids[reference] || '';
	}

	public static getUrnFromReference(reference: Shop_NetworkReference) {
		return `jts:shop:network:${this.getIdFromReference(reference)}`;
	}
}