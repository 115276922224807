import _get                 from 'lodash/get';
import ApiConnector         from '@/models/ApiConnector';
import { Collection }       from '@mathquis/modelx';
import { ConnectorResult }  from '@mathquis/modelx';
import { ConnectorResults } from '@mathquis/modelx';
import { Model }            from '@mathquis/modelx';

export default class MonolithApiConnector extends ApiConnector {
	protected _authorizationHeaderName = 'X-Platform-Authorization';
	public resultTotalPath = '[0].AppPagination.total_count';

	public async fetch(model: Model, options: object = {}) {
		const customOptions = { method: 'get', ...options };
		const response = await this.request(model.path, customOptions);
		const result = Object.values(_get(response, 'data.0', {})).at(0) as any;
		return new ConnectorResult(result, response);
	}

	public async list<T extends Model>(collection: Collection<T>, options: ConnectorListOptions = {}) {
		const customOptions = { method: 'get', ...{ ...options, params: { step: options.params.itemsPerPage, ...options.params } } };
		delete customOptions.params.itemsPerPage;
		
		const response = await this.request(collection.path, customOptions);
		const results = _get(response, 'data.0.AppPagination.items', []).map(i => Object.values(i).at(0)) as any;

		return new ConnectorResults(results, response);
	}

	public async save(model: Model, options: ConnectorSaveOptions = {}) {
		const data = options.patchAttributes || model.untransformedAttributes as Record<string, string>;
		const method = model.id ? 'put' : 'post';
		const customOptions = { data, method, ...options, headers: { 'Content-Type': undefined, ...options.headers } };
		const response = await this.request(model.path, customOptions);
		const result = Object.values(_get(response, 'data.0', {})).at(0) as any;
		return new ConnectorResult(result, response);
	}
}
