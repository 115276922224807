import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('deliverable_transfer_status')
@doc.path('/deliverable_transfer_statuses/{?id}')
export default class DeliverableTransferStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_DeliverableTransferStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Shop_DeliverableTransferStatusReference;

	public get color() {
		switch (this.reference) {
			case 'canceled':
			case 'refused':
				return 'red';
			case 'accepted':
				return 'green';
			case 'waiting':
				return 'orange';
		}
	}
}