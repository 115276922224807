import CodeKindModel            from '@models/code/CodeKindModel';
import CodeNetworkModel         from '@models/code/CodeNetworkModel';
import CodeStatusModel          from '@models/code/CodeStatusModel';
import CodeTypeModel            from '@models/code/CodeTypeModel';
import doc                      from '@widesk/decorators/doc';
import model                    from '@widesk/decorators/model';
import TimestampAble            from '@widesk/models/traits/TimestampAble';
import Blamable                 from '@/models/traits/Blamable';
import CodeOwnerModel           from '@models/code/CodeOwnerModel';
import { RenderNameProps }      from '@widesk/mixins/ApiModelRenderName';
import resolvableUrn            from '@widesk/decorators/resolvableUrn';
import DeliverableModel         from '@models/shop/DeliverableModel';
import DeliverableExchangeModel from '@models/shop/DeliverableExchangeModel';
import CodeServiceModel         from '@/models/CodeServiceModel';
import CodeUsageStatusModel     from '@models/code/CodeUsageStatusModel';

@model.urnResource('code')
@doc.path('/codes/{?id}')
export default class CodeModel extends Blamable(TimestampAble(CodeServiceModel)) {
	private declare _tsFilters: {
		'codeKind': id;
		'codeKind.reference': Code_CodeKindReference;
		'codeNetwork': id;
		'codeNetwork.reference': Code_CodeNetworkReference;
		'codeStatus': id;
		'codeStatus.reference': Code_CodeStatusReference;
		'codeType.codeEntertainment.entertainmentUrn': Urn;
		'codeUsageStatus.reference': Code_CodeUsageStatusReference;
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'owner': id;
		'owner.ownerUrn': Urn;
		'sourceUrn': Urn;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
		'value': id;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};	

	@doc.model(CodeOwnerModel) declare owner: CodeOwnerModel;
	@doc.model(CodeKindModel) declare codeKind: CodeKindModel;
	@doc.model(CodeNetworkModel) declare codeNetwork: CodeNetworkModel;
	@doc.model(CodeStatusModel) declare codeStatus: CodeStatusModel;
	@doc.model(CodeTypeModel) declare codeType: CodeTypeModel;
	@doc.model(CodeUsageStatusModel) declare codeUsageStatus: CodeUsageStatusModel;
	@doc.string declare value: string;

	@resolvableUrn({ attributeName: 'sourceUrn' })
	declare source: DeliverableModel | DeliverableExchangeModel;

	public renderName(props?: RenderNameProps<this> & ProductNameProps) {
		return super.renderName(props);
	}
}