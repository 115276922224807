import ExportStatusModel from '@models/shop/ExportStatusModel';
import ExportTypeModel   from '@models/shop/ExportTypeModel';
import doc               from '@widesk/decorators/doc';
import model             from '@widesk/decorators/model';
import ShopServiceModel  from '@/models/ShopServiceModel';
import TimestampAble     from '@widesk/models/traits/TimestampAble';
import Blamable          from '@/models/traits/Blamable';
import OrderModel        from '@models/shop/OrderModel';
import ApiCollection     from '@widesk/models/ApiCollection';
import _omitBy           from 'lodash/omitBy';
import { message }       from '@widesk/hooks/useMessage';
import DeliverableModel  from '@models/shop/DeliverableModel';
import ClientModel       from '@models/shop/ClientModel';

type ExportFilters = {
	client_csv: ModelFilters<ClientModel>;
	order_csv: ModelFilters<OrderModel>;
	deliverable_csv: ModelFilters<DeliverableModel>;
};

@model.urnResource('export')
@doc.path('/exports/{?id}')
export default class ExportModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: unknown;

	private declare _tsSorts: unknown;

	@doc.string declare dql: string;
	@doc.model(ExportStatusModel) declare exportStatus: ExportStatusModel;
	@doc.model(ExportTypeModel) declare exportType: ExportTypeModel;

	public static async export<R extends Shop_ExportTypeReference>(typeRef: R, collFilters: ExportFilters[R]) {
		const typeCollection = new ApiCollection(ExportTypeModel);
		await typeCollection.list();

		const type = typeCollection.find(m => m.reference === typeRef);

		const filters = _omitBy(collFilters, (_, key) => ['step', 'page'].includes(key) || key.startsWith('order['));

		const model = new ExportModel();
		await model.patch({ exportType: type?.urn, filters });

		message.success(`L'export a bien été enregistré, vous allez recevoir un email.`);
	}
}