import AntdIcon from '@ant-design/icons';

export type IconProps = {
	size?: number;
	color?: string;
	rotate?: number;
} & ({
	name: string;
	svg?: undefined;
} | {
	name?: undefined;
	svg: string;
});

const modules = import.meta.glob(['/src/assets/icons/*'], { eager: true });

export default function Icon(props: IconProps) {
	const { size, color, name, rotate, svg } = props;

	if (svg) {
		const Svg = (p: any) => <img alt="icon" src={svg} {...p} />;

		return <Svg width={size} height={size} />;
	}

	const component = (modules[`/src/assets/icons/${name}.svg`] as any)?.['ReactComponent'];

	if (component) {
		return <AntdIcon rotate={rotate} component={component} style={{ color, fontSize: size }} />;
	}

	return <span style={{ color: 'red' }}>Error icon</span>;
}
