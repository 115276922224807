import doc                 from '@widesk/decorators/doc';
import model               from '@widesk/decorators/model';
import TimestampAble       from '@widesk/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';
import FondationsUserModel from '@models/fondations/UserModel';
import CodeServiceModel    from '@/models/CodeServiceModel';

@model.urnResource('code_owner')
@doc.path('/code_owners/{?id}')
export default class CodeOwnerModel extends Blamable(TimestampAble(CodeServiceModel)) {

	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'ownerUrn': Urn;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.modelUrn(FondationsUserModel) declare owner: FondationsUserModel;

}
