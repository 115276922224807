export default [
	{
		handle: { crumb: () => 'Informations sur un code' },
		path: '/tools/scan_anything',
		lazy: async () => {
			const Module = await import('../pages/Tools/ScanAnything/ScanAnythingPage');
			return { Component: Module.default };
		},
	},
];
