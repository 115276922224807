import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.cacheDuration()
@model.urnResource('vat')
@doc.path('/vats/{?id}')
export default class VatModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'rate': id;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'rate': string;
		'updatedAt': string;
	};

	@doc.string declare label: string;
	@doc.string declare rate: string;

	public get rateFloat() {
		return parseFloat(this.rate || '0');
	}
}
