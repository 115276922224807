import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('shipment_status')
@doc.path('/shipment_statuses/{?id}')
export default class ShipmentStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_ShipmentStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Shop_ShipmentStatusReference;

	public get color() {
		switch (this.reference) {
			case 'delivered':
				return 'green';
			case 'waiting':
				return 'orange';
			case 'canceled':
				return 'red';
			case 'returned':
				return 'black';
			case 'shipped':
				return 'purple';
		}
	}
}
