import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('promo_code_status')
@doc.path('/promo_code_statuses/{?id}')
export default class PromoCodeStatusModel extends ShopServiceModel {
	@doc.string declare label: string;
	@doc.string declare reference: Shop_PromoCodeStatusReference;
	private declare _tsFilters: {
		'reference': Shop_PromoCodeStatusReference;
	};
	private declare _tsSorts: {
		'id': string;
	};

	public get color() {
		switch (this.reference) {
			case 'disabled':
				return 'red';
			case 'enabled':
				return 'green';
		}
	}
}
