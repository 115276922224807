import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.urnResource('currency')
@doc.path('/currencies/{?id}')
export default class CurrencyModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'alphaCode': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'name': string;
		'numCode': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'updatedAt': string;
	};

	@doc.string declare alphaCode: string;
	@doc.number declare minorUnit: number;
	@doc.string declare name: string;
	@doc.string declare numCode: string;
}
