import ClientGroupModel    from '@models/shop/ClientGroupModel';
import ProductPricingModel from '@models/shop/ProductPricingModel';
import doc                 from '@widesk/decorators/doc';
import model               from '@widesk/decorators/model';
import ShopServiceModel    from '@/models/ShopServiceModel';
import TimestampAble       from '@widesk/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';
import CurrencyModel       from '@models/shop/CurrencyModel';

@model.urnResource('product_pricing_client_group')
@doc.path('/product_pricing_client_groups/{?id}')
export default class ProductPricingClientGroupModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'clientGroup': id;
		'clientGroup.reference': Shop_ClientGroupReference;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'productPricing': id;
		'productPricing.product': id;
		'productPricing.product.entertainment': id;
		'productPricing.product.entertainment.entertainmentUrn': Urn;
		'productPricing.product.productGroup': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'price': string;
		'createdAt': string;
		'updatedAt': string;
	};

	@doc.model(ClientGroupModel) declare clientGroup: ClientGroupModel;
	@doc.model(CurrencyModel) declare currency: CurrencyModel;
	@doc.number declare priceInclTax: number;
	@doc.model(ProductPricingModel) declare productPricing: ProductPricingModel;

}
