import MonolithApiConnector from '@/models/MonolithApiConnector';
import ServiceModel         from '@models/ServiceModel';

const service = window.APP_CONFIG.services.find(service => service.id === 'scaf');

export const scafConnector = new MonolithApiConnector({ baseURL: service?.configurations.api_endpoint });

export default class ScafServiceModel extends ServiceModel {
	public static get connector() {
		return scafConnector;
	}

	public static serviceName = 'scaf';
	public static partitionName = 'jts';
}
